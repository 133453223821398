@use '../../assets/styles/colors.scss' as colors;

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 680px;
    padding-left: 70px;
    background-color: white;

    @media (max-width: 980px) {
        flex-direction: column;
        gap: 24px;
        height: min-content;
        padding: 14px;
        padding-bottom: 28px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    padding: 40px;
    border-right: 2.5px solid colors.$color-brand-primary-pure;

    @media (max-width: 980px) {
        border: none;
        align-items: center;
        padding: 12px;
        width: 100%;
    }
}

.title {
    margin-bottom: 8px;
    max-width: 640px;
    
    h1 {
        color: colors.$color-brand-primary-pure;
        font-size: 32px;
        letter-spacing: 8.8px;
    }

    @media (max-width: 980px) {
        h1 {
            font-size: 24px;
            line-height: 24px;
        }
    }
}



.address {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
        color: colors.$color-neutral-gray;
    }
    
    span {
        color: colors.$color-neutral-gray-light;
    }

    @media (max-width: 980px) {
        align-items: center;
        width: 100%;
    }
}
.contacts-action-button {
    margin-top: 24px;
    max-width: 500px;

    @media (max-width: 980px) {
        align-items: center;
        button {
            font-size: 14px;
        }
    }
}

.map-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}