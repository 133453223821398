.h1 {
    font-family: 'Glacial-Bold', sans-serif;
    font-size: 68px;
    line-height: 68px;
    font-weight: 400;

    @media screen and (max-width: 991px){
        font-size: 60px;
        line-height: 70px;
    }
    
    @media screen and (max-width: 680px){
        font-size: 40px;
        line-height: 50px;
    }
}

.subtitle {
    font-family: 'Open-sans', sans-serif;
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;

    @media (max-width: 680px) {
        font-size: 16px;
    }
}

.paragraph {
    font-family: 'Glacial', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;

    @media (max-width: 680px) {
        font-size: 12px;
    }
}

.span {
    font-family: 'Open-sans', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;

    @media screen and (max-width: 680px){
        font-size: 12px;
        line-height: 12px;
    }
}