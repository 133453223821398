@use '../../assets/styles/colors.scss' as colors;

.pilates-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: initial;
    gap: 16px;
    width: 100%;
    min-height: 80vh;
    padding: 120px 24px 32px 184px;
    background-image: url('https://i.ibb.co/0sRvhDG/DIVISAO-1.png');
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 980px) {
        align-items: center;
        justify-content: center;
        padding: 12px 12px;
        height: 80vh;
        gap: 32px;
    }

    @media (min-width: 1920px) {
       align-items: center;
    }
}

.pilates-section-texts {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 54px;
    height: 200px;
    width: 75%;

    @media (max-width: 980px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 22px;
        font-size: 12px;
        height: min-content;
    }

    @media (min-width: 1920px) {
        justify-content: flex-start;
    }
}

.pilates-section-text-left {
    display: flex;
    flex-direction: column;
    gap: 38px;
    font-family: 'Glacial-Bold', sans-serif;
    font-size: 32px;
    letter-spacing: 8.8px;
    line-height: 28px;
    color: colors.$color-brand-primary-pure;

    @media (max-width: 980px) {
        flex-direction: row;
        gap: 12px;
        font-size: 16px;
        letter-spacing: 6.8px;
    }
}

.pilates-section-text-right {
    width: 570px;
    font-family: 'Glacial', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: colors.$color-neutral-gray-light;

    @media (max-width: 980px) {
        width: 300px;
        font-size: 16px;
        height: min-content;

        p {
            margin-bottom: 10px;
        }
    }
}

.pilates-section-button {
    margin-top: 32px;
    margin-right: auto;
    // margin-left: 280px;

    @media (max-width: 980px) {
        margin-left: auto;
    }
}