@use '../../assets/styles/colors.scss' as colors;

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: colors.$color-brand-secondary-light;
    width: 100%;
    min-height: 40vh;

    @media (max-width: 680px) {
        flex-direction: column;
        gap: 32px;
        min-height: 380px;
    }

}
.footer-left-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    color: colors.$color-neutral-white;

    span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 4.8px;
        text-transform: uppercase;
    }
}

.size {
    max-width: 230px;
}

.footer-middle-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 200px;
}

.footer-container-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: colors.$color-neutral-white;
}

.privacy-policy-button {
    text-decoration: underline;
}

.footer-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

%button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 52px;
}

.facebook-button, .youtube-button, .instagram-button, .whatsapp-button {
    @extend %button;
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: colors.$color-neutral-white;

    span {
        font-size: 12px;
        line-height: 12px;
        font-weight: 300;
    }

    @media (max-width: 680px) {
        justify-content: center;
        align-items: center;
    }
}