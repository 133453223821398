@use '../../assets/styles/colors.scss' as colors;

.container {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: colors.$color-brand-primary-light;
    padding: 40px;

    @media (max-width: 680px) {
        padding: 16px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    padding: 40px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 12px 5px #C4C4C4; 
    box-shadow: 0px 4px 12px 5px #C4C4C4;

    p {
        text-align: justify;
    }

    h1 {
        margin-left: auto;
        margin-right: auto;
    }

    span {
        font-weight: bold;
        color: #18727D;
    }

    h1, h2 {
        color: colors.$color-brand-primary-pure;
    }

    @media (max-width: 680px) {
        padding: 22px;

        h1 {
            font-size: 32px;
        }

    }
}