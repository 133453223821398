@use '../../assets/styles/colors.scss' as colors;

.container-course {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 330px;
    height: 470px;
    background-color: white;
    border-top-left-radius: 45%;
    border-top-right-radius: 45%;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    padding: 10px;
}

.card-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-clip:border-box;
    width: 320px;
    height: 344px;
    border-top-left-radius: 45%;
    border-top-right-radius: 45%;
}

.card-title, .card-description, .card-duration {
    font-family: 'Glacial', sans-serif;
    text-align: center;
    max-width: 300px;
}

.card-title {
    color: colors.$color-brand-primary-pure;
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
}

.card-subtitle {
    color: colors.$color-brand-primary-pure;
    font-size: 16px;
    font-weight: bold;
}
.card-description {
    color: colors.$color-neutral-gray-light;
    font-size: 16px;
    margin-top: 6px;
}

.card-duration {
    color: colors.$color-neutral-gray-light;
    font-size: 14px;
    margin-top: 10px;
}