
@use '../../../assets/styles/colors.scss' as colors;

.header-section {
    min-width: 100%;
    min-height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 980px) {
        background: white;
        min-height: 80vh;
    }
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;

    img {
        width: 300px;

        @media (max-width: 980px) {
            width: 200px;
        }
    }
}

.paragraph {
    position: absolute;
    bottom: 250px;
    right: 190px;
    width: 420px;
    opacity: 1;
}

