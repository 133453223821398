@use '../../assets/styles/colors.scss' as colors;

%button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 380px;
    height: 54px;
    padding: 15px;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: 'Glacial', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.primary {
    @extend %button;
    background-color: colors.$color-brand-primary-pure;
    color: colors.$color-neutral-white;
    border-radius: 26px;
    font-weight: 600;
    text-transform: none;
    font-size: 14px;
    max-width: 320px;
    transition: color 500ms ease, background-color 500ms ease;

    &:hover {
        background-color: colors.$color-brand-primary-light;
        color: colors.$color-brand-primary-pure;
    }
}

.secondary {
    @extend %button;
    background-color: colors.$color-brand-secondary-light;
    color: colors.$color-neutral-white;
    border-radius: 2px;
    border: 2px solid white;
    transition: color 500ms ease, background-color 500ms ease;

    &:hover {
        background-color: colors.$color-brand-secondary-lightest;
        color: colors.$color-neutral-white;
    }
}

.tertiary {
    @extend %button;
    font-family: 'Open-sans', sans-serif;
    font-weight: 600;
    font-weight: 12px;
    font-size: 12;
    letter-spacing: 1px;
    text-transform: none;
    background-color: transparent;
    color: colors.$color-brand-primary-pure;
    border: 2px solid colors.$color-brand-primary-pure;
    border-radius: 26px;
    transition: opacity 300ms ease;

    &:hover {
        color: colors.$color-brand-primary-pure;
        opacity: 0.8;
    }
}