@use '../../assets/styles/colors.scss' as colors;

.benefits-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    min-height: 80vh;
    background-color: colors.$color-brand-primary-pure;

    @media (max-width: 980px) {
        flex-direction: column;
        min-height: 90vh;
    }
}

.benefits-section-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 280px;
    color: white;
    text-align: center;

    img {
        height: 216px;

        @media (max-width: 980px) {
            height: 106px;
        }
    }

    span {
        font-size: 22px;
        line-height: 24px;

        @media (max-width: 980px) {
            font-size: 16px;
        }
    }
}

.size {
    max-width: 240px;
}