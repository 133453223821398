@use '../../assets/styles/colors.scss' as colors;

.testimonials-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 620px;
    padding: 40px;
    background-image: url('../../assets/img/bg-primary-circles.png');
    color: colors.$color-brand-primary-pure;

    @media (max-width: 680px) {
        align-items: center;
        height: min-content;
        padding: 32px;
        gap: 32px;

        h1 {
            font-family: 'Glacial-Bold', sans-serif;
            text-transform: uppercase;
            letter-spacing: 8.8px;
            font-size: 28px;
            line-height: 28px;

            @media (max-width: 980px) {
                font-size: 24px;
                line-height: 24px;
            }
        }
    }
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 380px;

    span {
        margin-top: 10px;
    }
}

.description {
    font-family: "Glacial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-align: justify;
    min-height: 230px;

    @media (max-width: 680px) {
        font-size: 16px;
        min-height: 130px;
    }
}

.testimonials-container {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    color: colors.$color-brand-primary-pure;
    font-weight: bold;

    @media (max-width: 680px) {
        max-width: 280px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 32px;
    }
}