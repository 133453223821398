@use '../../../assets/styles/colors.scss' as colors;

.courses-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
    width: 100%;
    height: 90vh;
    background: url('https://i.ibb.co/K0GbJ70/DIVISAO-2.png');
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 980px) {
        overflow: hidden;
    }
}