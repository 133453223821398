$color-brand-primary-pure: #946702;
$color-brand-primary-light: #E6D5C0;
$color-brand-primary-lightest: #E6E8EA;
$color-brand-secondary-pure: #145DA0;
$color-brand-secondary-light: #18727D;
$color-brand-secondary-lightest: #35D5D0;

$color-neutral-white: #FFFFFF;
$color-neutral-gray: #333;
$color-neutral-gray-light: #8b8b8b;


