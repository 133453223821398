@use '../../../assets/styles/colors.scss' as colors;

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    width: 100%;
    height: 150px;
    background-color: white;
    font-family: 'Glacial', sans-serif;

    @media (max-width: 650px) {
        height: 90px;
    }
}

.menu {
    border: none;
    color: colors.$color-brand-primary-pure;
    height: 24px;
}

.span {
    color: colors.$color-brand-primary-pure;
    margin-left: 2%;
    margin-top: 6px;

    span {
        font-size: 24px;
    }
}

.home {
    span {
        color: colors.$color-brand-primary-pure;
        font-weight: bold;
        font-size: 18px;
        text-decoration: underline;
    }
}

.button {
    margin-left: 40%;
    margin-right: auto;
}